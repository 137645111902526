<template>
    <div class="container parent-wrap">
       <!-- <div class="d-flex justify-content-center align-items-center">
        <img src="../assets/images/elite-logo.png"   class="elite-logo  main-logo"  alt="Logo">
        <div class="agent-wrap">
            <img  class="img-agent" src="../assets/images/agent_image.png">
            <div class="detail-agent">
              <h4 class="mb-0"><a class="agent-name">Pukar Hamal</a></h4>
              <small>Phone: 419-377-1007 | 
              <a href="mailto:pukarhamal@gmail.com">pukarhamal@gmail.com</a></small>
            </div>
        </div>
      </div> -->
      <div class="d-flex justify-content-center" v-if="showAcessCode">
      <img
        src="../assets/images/elite-logo.png"
        class="elite-logo"
        alt="Logo"
      />
    </div>
    <div class="d-flex access-image" v-else>
      <img src="../assets/images/elite-logo.png" class="elite-logo" alt="Logo" />
      <img
        src="../assets/images/elite-logo.png"
        class="elite-logo"
        alt="Logo"
      />
    </div>
       <div class="row m-3" v-if="showAcessCode">
          <div class="col-md-8 access-container">
            <img src="../assets/images/lock.svg"/>
            <p>Please Enter Your Access Code,<br>to access site</p>
            <input  v-b-tooltip.active.bottom title="PHOH3123 "  type="text" placeholder="Eg:DMIA1212" />
            <b-button @click="showDetailPage" class="submit-btn"
                >Submit Access Code </b-button>
          </div>
       </div>
       <div  class="row m-3"  v-if="!showAcessCode">
        <b-container>
            <div class="col-md-8 access-container">
               <div class="popUp-header conform-header" style="border-bottom: 0px !important ">
                 <div class="unlock-header">
                   <img src="../assets/images/unlock.svg" alt="unlock"> <h4> Please confirm that you are accessing the site with the correct representative access
                   code.</h4>
                  </div>
               </div>
               <div class="popUp-details">
                    <div valign="top"><b>Pukar Hamal</b> <br>PHOH3123<br>pukarhamal@gmail.com<br>(419) 377-1007
                    </div>
                    <div class="access-action-btn">
                      <a href="/landing-page">
                        <input type="submit" name="submit_Agent" id="submit_Agent" value="Confirm"  class="btn btn-success mr-xs mb-sm mb-0" />
                      </a>
                      <a href="/access-page">
                        <input type="submit" name="submit_Agent" id="submit_Agent" value="Cancel"  class="btn btn-cancel mr-xs mb-sm mb-0" />
                      </a>
                    <div>
               </div>
            </div>
               </div>
          </div>
        </b-container>
       </div>
       <div class="access-footer">
          <div  class="core-logo">
            <img src="../assets/images/core-logo.png" >
          </div>
          <div class="access-menu d-flex justify-content-center" style="margin: auto">
            <ul class="access-ul">
          <li>
            <a :href="menuLink.routeToGroupRegistration" target="_blank"
            ><span> GROUP APPLICATION</span></a
            >
          </li>
          <li>
            <a :href="menuLink.routeToRepRegistration" target="_blank"
            ><span> REP REGISTRATION</span></a
            >
          </li>
          <li>
            <a :href="menuLink.routeToRepDashboard" target="_blank"
            ><span> REP DASHBOARD</span></a
            >
          </li>
          <li>
            <a :href="menuLink.routeToMemberDashboard" target="_blank"
            ><span> MEMBER DASHBOARD</span></a
            >
          </li>
        </ul>
    
        </div>
          <div class="copyright">
            <p >Copyright &copy; {{currentYear}} </p>
            <p class="copyright-left"> </p>
            <p>All Rights Reseved</p>
          </div>
        </div>
    </div>
    </template>
    
    <script>
    export default {
      name: "AccessPage",
      data () {
        return {
          showAcessCode: true,
          menuLink: {
            routeToRepDashboard: process.env.VUE_APP_REP_DASHBOARD,
            routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
            routeToRepRegistration: process.env.VUE_APP_REP_REGISTRATION,
            routeToGroupRegistration: process.env.VUE_APP_GROUP_REGISTRATION,
           }
        }
      },
      methods: {
        showDetailPage () {
          let app = this
          app.showAcessCode = false
        }
      },
      computed: {
        currentYear() {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        return currentYear
        }
      },
    }
    </script>
    
    <style scoped>
    .access-image {
       justify-content: space-evenly;
    }
    </style>